export function createBackground() {

  const squareCanvas = document.querySelector("#canvas1");
  squareCanvas.width = window.innerWidth;
  squareCanvas.height = window.innerHeight;
  const ctx = squareCanvas.getContext("2d");
  const squaresArray = [];

  window.addEventListener("mousemove", (e) => {
    squaresArray.forEach((square) => {
      const displacement = square.size / 300;
      square.x -= e.movementX * displacement;
      square.y -= e.movementY * displacement;
    });
    animate();
  });

  window.addEventListener('resize', () => {
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    squareCanvas.width = window.innerWidth;
    squareCanvas.height = window.innerHeight;
  })

  function getRandomSeed(min = 0, max = 1) {
    return min + Math.ceil(Math.random() * max);
  }

  class Square {
    constructor(id, x, y, size, maxSize, color, growSpeed) {
      this.id = id;
      this.x = x;
      this.y = y;
      this.initSize = size;
      this.size = size;
      this.maxSize = maxSize;
      this.color = color;
      this.growSpeed = growSpeed;
    }

    #getColorWithOpacity() {
      let hexOpacity = Math.floor(
        (1 - (this.size / this.maxSize).toFixed(2)) * 255
      ).toString(16);
      if (hexOpacity.length === 1) hexOpacity = "0" + hexOpacity;
      return hexOpacity;
    }

    draw() {
      ctx.fillStyle = this.color;
      ctx.fillRect(
        this.x - this.size / 2,
        this.y - this.size / 2,
        this.size,
        this.size
      );
    }

    update() {
      if (this.size >= this.maxSize) {
        const currentIndex = squaresArray.findIndex(
          (square) => square.id === this.id
        );
        squaresArray[currentIndex] = createSquare();

        return;
      }
      this.size += this.growSpeed;
      this.color =
        this.color.slice(0, this.color.length - 2) + this.#getColorWithOpacity();
      this.draw();
    }

  }


  const getCurrentColor = () =>
    getComputedStyle(document.querySelector("html")).getPropertyValue(
      "--tial-color-1"
    ) + "00";

  function animate() {
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    squaresArray.forEach((square) => {
      square.update();
    });
  }

  function createSquare() {
    return new Square(
      getRandomSeed(1, 10000),
      getRandomSeed(0, window.innerWidth),
      getRandomSeed(0, window.innerHeight),
      getRandomSeed(5, 10),
      getRandomSeed(40, 50),
      getCurrentColor(),
      getRandomSeed(3, 10) / 100
    );
  }

  function init() {
    for (let i = 0; i <= 50; i += 1) {
      squaresArray.push(createSquare());
    }
    animate();
  }

  init();
}