import { ChangeEvent, useState } from "react";

type Props = {
  onOk: (newURL: string) => void;
  onCancel: () => void;
};

export function ImageEdit({ onOk, onCancel }: Props): JSX.Element {
  const [urlType, setUrlType] = useState("Url");
  const [url, setUrl] = useState("");

  const onUrlTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlType(e.target.value);
  };

  const onUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        if (typeof reader.result === "string") setUrl(reader.result || "");
      });
      return;
    }
    setUrl(e.target.value);
  };

  const onConfirm = () => {
    onOk(url);
  }

  return (
    <div className="inputForm">
      <div className="imageInputWrapper">
        <div className="radioButtonsWrap">
          <label>
            <input
              type="radio"
              name="urlType"
              value="file"
              checked={urlType === 'file'}
              onChange={onUrlTypeChange}
            />
            Local file
          </label>
          <label>
            <input
              type="radio"
              name="urlType"
              value="Url"
              checked={urlType === 'Url'}
              onChange={onUrlTypeChange}
            />
            URL
          </label>
        </div>
        <div className="inputWrap">
          {" "}
          {urlType === "file" ? (
            <input type="file" multiple={false} onChange={onUrlChange} />
          ) : null}
          {urlType === "Url" ? (
            <input type="text" onChange={onUrlChange} />
          ) : null}
        </div>
      </div>
      <p>When you edit the image part of your data - all the anchors are being reset, since the underlying image is not going to match anchors position boundings.</p>
      <button onClick={onConfirm}>Save</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
}
