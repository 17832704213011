import './Controls.scss';

type ControlsProps = {
    highlightColor: string;
    markerTextColor: string;
    frameTextColor: string;
    imageFrameBorderRadius: number;
    textFrameBorderRadius: number;
    imageFrameBorderWidth: number;
    textOutlineWidth: number;
    isEditable: boolean;
    setHighlightColor: (value: string) => void;
    setMarkerTextColor: (value: string) => void;
    setFrameTextColor: (value: string) => void;
    setImageFrameBorderRadius: (value: number) => void;
    setTextFrameBorderRadius: (value: number) => void;
    setImageFrameBorderWidth: (value: number) => void;
    setTextOutlineWidth: (value: number) => void;
    setIsEditable: (value: boolean) => void;
}


export function Controls(props : ControlsProps) {
    const {highlightColor,
        markerTextColor,
        frameTextColor,
        imageFrameBorderRadius,
        textFrameBorderRadius,
        imageFrameBorderWidth,
        textOutlineWidth,
        isEditable,
        setHighlightColor,
        setMarkerTextColor,
        setFrameTextColor,
        setImageFrameBorderRadius,
        setTextFrameBorderRadius,
        setImageFrameBorderWidth,
        setTextOutlineWidth,
        setIsEditable} = props;
 
return <ul className="controls">
  <li>
    <input
      type="color"
      value={highlightColor}
      onChange={(e) => setHighlightColor(e.target.value)}
    />
    <span>Highlight color ({highlightColor}).</span>
  </li>
  <li>
    <input
      type="color"
      value={markerTextColor}
      onChange={(e) => setMarkerTextColor(e.target.value)}
    />
    <span>Marker text color ({markerTextColor}).</span>
  </li>
  <li>
    <input
      type="color"
      value={frameTextColor}
      onChange={(e) => setFrameTextColor(e.target.value)}
    />
    <span>Image frame text color ({frameTextColor}).</span>
  </li>
  <li>
    <input
      type="range"
      min="0"
      max="50"
      step="1"
      value={imageFrameBorderRadius}
      onChange={(e) =>
        setImageFrameBorderRadius(Number(e.target.value))
      }
    />
    <span>
      Image frame border radius ({imageFrameBorderRadius}%).
    </span>
  </li>
  <li>
    <input
      type="range"
      min="0"
      max="50"
      step="1"
      value={textFrameBorderRadius}
      onChange={(e) =>
        setTextFrameBorderRadius(Number(e.target.value))
      }
    />{" "}
    <span>
      Text frame border radius ({textFrameBorderRadius}%).
    </span>{" "}
  </li>
  <li>
    {" "}
    <input
      type="range"
      min="0"
      max="5"
      step="1"
      value={imageFrameBorderWidth}
      onChange={(e) =>
        setImageFrameBorderWidth(Number(e.target.value))
      }
    />{" "}
    <span>
      Image frame border width ({imageFrameBorderWidth}px).
    </span>{" "}
  </li>
  <li>
    {" "}
    <input
      type="range"
      min="0"
      max="5"
      step="1"
      value={textOutlineWidth}
      onChange={(e) => setTextOutlineWidth(Number(e.target.value))}
    />{" "}
    <span>Text frame outline width ({textOutlineWidth}px).</span>{" "}
  </li>
  <li>
    {" "}
    <label>
      <input
        type="checkbox"
        checked={isEditable}
        onChange={(e) => setIsEditable(e.target.checked)}
      />
      Is editable
    </label>
  </li>
</ul>
}
