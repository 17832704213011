import { AnchorType } from "@alanor87/tial/dist/esm/types/types/types.js";
import JsonFormatter from 'react-json-formatter'
import './AnchorsList.scss';

type AnchorListProps = {
  anchorsArray: AnchorType[];
};

export function AnchorsList({ anchorsArray }: AnchorListProps) {
    const jsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'white' },
        numberStyle: { color: 'darkorange' }
      }
  return <div className="anchorsList"><JsonFormatter json={JSON.stringify(anchorsArray)} tabWith={4} jsonStyle={jsonStyle} /></div>;
}
