const mock_data = {
  anchorImageUrl:
    "https://res.cloudinary.com/none-for-now/image/upload/v1721494911/Section_9_render_by_oxeyclean-d5qpjew_vsv0wt.webp",
  anchorText:
    "Ghost in the shell - one of my favorite anime movies. Here are some characters : \n - Kusanagi Motoko.\n - Bato.\n - Aramaki Yoski.\n - Togusa.\n - Puppeter.\n Together they form the Section 9. You can practice a little bit in marking the most pretty, old, handsome and cool looking characters here).",
  anchorsArray: [
    {
    "_id": "Motoko034484237874089940",
    "anchorText": "Kusanagi Motoko",
    "anchorTextStartPos": 85,
    "anchorFrameCoords": [
    35.724137931034484,
    18.153364632237874
],
    "anchorFrameSize": [
    26.89655172413793,
    80.28169014084507
]
},
    {
    "_id": "Bato275862292645112603",
    "anchorText": "Bato",
    "anchorTextStartPos": 105,
    "anchorFrameCoords": [
    25.93103448275862,
    3.912363067292645
],
    "anchorFrameSize": [
    17.379310344827587,
    25.19561815336463
]
},
    {
    "_id": " Yoski344827056336119897",
    "anchorText": "Aramaki Yoski",
    "anchorTextStartPos": 114,
    "anchorFrameCoords": [
    68.41379310344827,
    25.352112676056336
],
    "anchorFrameSize": [
    30.06896551724138,
    71.20500782472612
]
},
    {
    "_id": "Togusa655174269171128709",
    "anchorText": "Togusa",
    "anchorTextStartPos": 132,
    "anchorFrameCoords": [
    58.758620689655174,
    10.015649452269171
],
    "anchorFrameSize": [
    17.79310344827586,
    37.8716744913928
]
}
]
};

export { mock_data };
