import { TextEdit } from "./TextEdit";
import { ImageEdit } from "./ImageEdit";
import { createPortal } from "react-dom";

const container = document.querySelector("#portal-root")!;

export type ModalTypes = "text" | "image" | ""

type Props = {
  modalType: ModalTypes;
  onTextChange: (newText: string) => void;
  onImageChange: (newImageUrl : string) => void;
  onCancel: () => void;
};

export function Modal({ modalType, onTextChange, onImageChange, onCancel }: Props) {
  const getModalComponenet = () => {
    switch (modalType) {
      case "text": {
        return <TextEdit onOk={onTextChange} onCancel={onCancel} />;
      }
      case "image": {
        return <ImageEdit onOk={onImageChange} onCancel={onCancel} />;
      }
      case "": {
        return null;
      }
    }
  };

  return createPortal(
    <div className="modalBackdrop">{getModalComponenet()}</div>,
    container
  );
}
