import React, { ChangeEvent, useState } from "react";

type Props = {
  onOk: (newText: string) => void;
  onCancel: () => void;
};

export function TextEdit({ onOk, onCancel }: Props): JSX.Element {
  const [text, setText] = useState("");

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value || "");
  };

  const onSaveText = () => {
    onOk(text);
  };

  return (
    <div className="inputForm">
      <textarea value={text} onChange={onTextChange} />
      <p>When you edit the text part of your data - all the anchors are being reset, since the underlying string is not going to match anchors position boundings.</p>
      <button onClick={onSaveText}>Save</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
}
