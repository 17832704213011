import { mock_data } from "./mock_data";
import { Controls } from "./Controls/Controls";
import { AnchorsList } from "./AnchorsList/AnchorsList";
import { Modal } from "./Modal/Modal";
import { AnchorWrapper, AnchorText, AnchorImage } from "@alanor87/tial";
import { AnchorType } from "@alanor87/tial/dist/esm/types/types/types.js";
import { memo, useEffect, useState } from "react";
import { createBackground } from "./particles.js";

import { ModalTypes } from "./Modal/Modal";
import "./App.scss";

const ParticleCanvas = memo(() => {
  useEffect(() => {
    createBackground();
  }, []);
  return <canvas id="canvas1" />;
});

function App() {
  const [anchorsData, setAnchorsData] = useState<{
    anchorImageUrl: string;
    anchorText: string;
    anchorsArray: AnchorType[];
  }>(mock_data);
  const [isEditable, setIsEditable] = useState(false);
  const [modalType, setModalType] = useState<ModalTypes>("");
  const [highlightColor, setHighlightColor] = useState("#f07523");
  const [markerTextColor, setMarkerTextColor] = useState("#000000");
  const [frameTextColor, setFrameTextColor] = useState("#4d5155");
  const [imageFrameBorderRadius, setImageFrameBorderRadius] = useState(0);
  const [textFrameBorderRadius, setTextFrameBorderRadius] = useState(0);
  const [imageFrameBorderWidth, setImageFrameBorderWidth] = useState(1);
  const [textOutlineWidth, setTextOutlineWidth] = useState(1);

  const [controlsOrAnchors, setControlsOrAnchors] = useState<
    "controlsAreVisible" | "anchorsAreVisible"
  >("controlsAreVisible");

  const onAnchorsUpdate = (anchorsArray: AnchorType[]) => {
    console.log("An example of generated anchors array : ", anchorsArray);
    setAnchorsData({ ...anchorsData, anchorsArray });
  };

  const onAnchorSelect = (_id: string) => {
    console.log("Selected id : ", _id);
  };

  const onTextChange = (newText: string) => {
    setAnchorsData({
      ...anchorsData,
      anchorText: newText,
      anchorsArray: [],
    });
    closeModal();
  };

  const onImageChange = (newImageUrl: string) => {
    setAnchorsData({
      ...anchorsData,
      anchorImageUrl: newImageUrl,
      anchorsArray: [],
    });
    closeModal();
  };

  const switchToControls = () => setControlsOrAnchors("controlsAreVisible");

  const switchToAnchorsList = () => setControlsOrAnchors("anchorsAreVisible");

  const resetSettings = () => {
    setIsEditable(false);
    setHighlightColor("#f07523");
    setMarkerTextColor("#000000");
    setFrameTextColor("#4d5155");
    setImageFrameBorderRadius(0);
    setTextFrameBorderRadius(0);
    setImageFrameBorderWidth(1);
    setTextOutlineWidth(1);
    setAnchorsData(mock_data);
  };

  const openModal = (modalType: "text" | "image" | "") => (e: any) => {
    setModalType(modalType);
  };

  const closeModal = () => {
    setModalType("");
  };

  const imageFrameStyle = {
    borderRadius: imageFrameBorderRadius + "%",
    boxShadow: "inset 0 0 2px 4px grey",
    borderWidth: imageFrameBorderWidth + "px",
    textColor: frameTextColor,
  };

  const textMarkerStyle = {
    outlineWidth: textOutlineWidth + "px",
    borderRadius: textFrameBorderRadius + "px",
    textColor: markerTextColor,
  };

  return (
    <div className="App">
      <header>
        <div className="libName">
          <span>T</span>ext{" "}
          <span>I</span>mage{" "}
          <span>A</span>nchoring{" "}
          <span>L</span>ibrary{" "}
        </div>
        <div className="links">
          <a href="https://www.npmjs.com/package/@alanor87/tial"
          target="_blank"
          rel="noreferrer">npm</a>
          <a href="https://github.com/alanor87/tial"
          target="_blank"
          rel="noreferrer">gitHub</a>
        </div>
      </header>
      <AnchorWrapper
        className="main"
        initialAnchorsData={anchorsData}
        highlightColor={highlightColor}
        onAnchorsUpdate={isEditable ? onAnchorsUpdate : undefined}
        onAnchorSelect={onAnchorSelect}
      >
        <div>
          <AnchorImage
            imageFrameStyle={imageFrameStyle}
            className="imageWrapper"
          />
        </div>

        <div className="textAndControls">
          <AnchorText
            className="myAnchorText"
            textMarkerStyle={textMarkerStyle}
            title="Alt + mouse select to select text to anchor"
          />
          <ul className="manual">
            {" "}
            <li>
              In order to create an anchor - hold Alt key and select required
              text. The <mark>Create anchor</mark> button will appear.
            </li>
            <li>
              Then - draw the area of the anchor on the image itself, as if you
              would draw the rectangle in Paint or similar image editor.
            </li>
            <li>And finally - click on it, as the text inside hints.</li>
            <li>
              Of course - do not forget to check <mark>is editable</mark> in
              Properties section below before creating an anchor.
            </li>
            <div style={{ position: "relative" }}>
              <div className="buttonsWrapper">
                <button onClick={switchToControls}>Controls</button>
                <button onClick={switchToAnchorsList}>AnchorsList</button>
              </div>
            </div>
          </ul>
          <div className="controlsAndAnchorListWrapper">
            <div className={"slider " + controlsOrAnchors}>
              <Controls
                highlightColor={highlightColor}
                markerTextColor={markerTextColor}
                frameTextColor={frameTextColor}
                imageFrameBorderRadius={imageFrameBorderRadius}
                textFrameBorderRadius={textFrameBorderRadius}
                imageFrameBorderWidth={imageFrameBorderWidth}
                textOutlineWidth={textOutlineWidth}
                isEditable={isEditable}
                setHighlightColor={setHighlightColor}
                setMarkerTextColor={setMarkerTextColor}
                setFrameTextColor={setFrameTextColor}
                setImageFrameBorderRadius={setImageFrameBorderRadius}
                setTextFrameBorderRadius={setTextFrameBorderRadius}
                setImageFrameBorderWidth={setImageFrameBorderWidth}
                setTextOutlineWidth={setTextOutlineWidth}
                setIsEditable={setIsEditable}
              />
              <AnchorsList anchorsArray={anchorsData.anchorsArray} />
            </div>
          </div>

          <div className="footerButtonsWrapper">
            <button type="button" onClick={openModal("text")}>
              Edit text
            </button>
            <button type="button" onClick={openModal("image")}>
              Change image
            </button>
            <button type="button" onClick={resetSettings}>
              Reset
            </button>
          </div>
        </div>
      </AnchorWrapper>
      {modalType && (
        <Modal
          modalType={modalType}
          onTextChange={onTextChange}
          onImageChange={onImageChange}
          onCancel={closeModal}
        />
      )}
      <ParticleCanvas />
    </div>
  );
}

export default App;
